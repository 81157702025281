import { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function CaptchaModal({captcha, setCaptcha, display, setDisplay}) {
    const modalRef = useRef(null);
    useEffect(() => {
        const clickOutside = (e) => {
            if(modalRef.current && !modalRef.current.contains(e.target)) {
                setDisplay(false);
            }
        }
        document.addEventListener('click', clickOutside, true);
        return () => {
            document.removeEventListener('click', clickOutside, true);
        };
        // eslint-disable-next-line
    }, []);
    return(
        <div id="defaultModal" aria-hidden="true" className={"fixed transition-all duration-500 ease-in-out top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden bg-base/75 md:inset-0 "+(display?"visible opacity-100":"invisible opacity-0")}>
            <div ref={modalRef} className="relative font-alternative w-full mx-auto my-20 max-w-xl md:h-auto bg-base">
                <div className="relative rounded-lg shadow bg-base_20">
                    <div className="flex items-start justify-between p-4 border-b rounded-t border-base_40">
                        <h3 className="text-2xl font-semibold text-neutral">
                            Human Verification
                        </h3>
                        <button type="button" onClick={() => {setDisplay(false)}} className="text-base_60 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-base_40 hover:text-neutral" data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-6 space-y-6">
                        {
                            captcha === "" ?
                            <>
                                <h1 className="text-center font-semibold">One final step before you are able to send your message. Please prove your identity as a human ...</h1>
                            </>
                            :
                            <>
                                <h1 className="text-center font-semibold">You verified your identity successfully, now you can continue to send your message.</h1>
                                <button type="button" onClick={() => {setDisplay(false)}} className="block mx-auto bg-alternative hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center" >
                                    <span className="flex items-center">              
                                        Continue
                                    </span>
                                </button>
                            </>
                        }
                        <div className={"mx-auto max-w-[300px] my-4 "+(captcha===""?"block visible opacity-100":"absolute invisible opacity-0")}>
                            <ReCAPTCHA onChange={(value) => {setCaptcha(value)}} onExpired={() => {setCaptcha("")}} onErrored={() => {setCaptcha("")}} theme="dark" sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}