import React from 'react';
import Root from "./Root";
import Main from "./views/Main";
import Profile from "./views/Profile";
import Contact from "./views/Contact";
import Browse from "./views/Browse";
import Experience from "./views/Experience";
import NotFound from './views/NotFound';
import { createBrowserRouter } from "react-router-dom";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <NotFound />,
        children: [
            { 
                path: "/", 
                element: <Main /> 
            },
            { 
                path: "/profile", 
                element: <Profile /> 
            },
            { 
                path: "/experience", 
                element: <Experience /> 
            },
            { 
                path: "/browse", 
                element: <Browse /> 
            },
            { 
                path: "/contact", 
                element: <Contact /> 
            },
        ],
    },
]);

export default Router;