import { useNavigate } from "react-router-dom";
import PortfolioIcon from "../media/images/portfolio_icon.png";
import ArticleIcon from "../media/images/article_icon.png";


export default function Main() {
    const navigate = useNavigate();
    return (
        <div className="absolute top-0 left-0 w-full h-full bg-base">
            <div className="absolute hidden w-full lg:block h-96" />
            <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-8 md:mx-auto text-center lg:max-w-2xl md:mb-10">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-neutral sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0  w-32 -mt-8 -ml-20 text-primary lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                                <defs> <pattern id="2c67e949-4a23-49f7-bf27-ca140852cf21" x="0" y="0" width=".135" height=".30"><circle cx="1" cy="1" r=".7" /></pattern></defs><rect fill="url(#2c67e949-4a23-49f7-bf27-ca140852cf21)" width="52" height="24" />
                            </svg>
                            <span className="relative font-secondary">Welcome</span>
                        </span>
                    </h2>
                    {/* <p className="text-neutral font-semibold font-alternative md:text-lg">
                        Maximizing convenience and accessibility for busy recruiters, I present two versions of my portfolio.
                    </p> */}
                </div>
                <div className="max-w-screen-md mb-4 sm:mx-auto">
                    <div className="flex items-center mt-20  group lg:max-w-xl">
                        <div className="flex items-center justify-center w-16 h-16 rounded-full mx-3">
                            <img src={PortfolioIcon} className="w-16 h-16" alt="" />
                        </div>
                        <h2 className="font-sans text-2xl font-bold leading-none tracking-tight md:text-4xl">
                            <span className="inline-block mb-2 font-alternative">Personal Portfolio</span>
                            <div className="h-[0.35rem] rounded-lg ml-auto duration-300 origin-left transform bg-primary scale scale-x-[0.3] group-hover:scale-x-100" />
                        </h2>
                    </div>
                    <p className="m-4 text-neutral font-semibold font-alternative md:text-lg">
                        Maximizing convenience and accessibility for busy recruiters, I present two versions of my portfolio.
                    </p>
                </div>
                <div className="grid max-w-screen-md gap-10 md:grid-cols-2 sm:mx-auto">
                    <div className="max-w-[400px] m-auto">
                        <div className="p-8 bg-base_20 rounded-lg">
                            <div className="mb-6 text-center">
                                <p className="text-2xl font-bold mb-5 font-secondary text-neutral">
                                    Concise Skills Portfolio
                                </p>
                                <div className="flex items-center justify-center">
                                    <p className="mr-2 text-5xl font-semibold text-neutral lg:text-6xl">
                                        2
                                    </p>
                                    <p className="text-lg text-base_60">&asymp; minutes</p>
                                </div>
                            </div>
                            <ul className="my-8 space-y-2 font-alternative font-medium text-[16px]">
                                <li className="flex items-center">
                                    <div className="mr-3">
                                        <svg className="w-4 h-4 text-primary" viewBox="0 0 24 24" strokeLinecap="round" strokeWidth="2">
                                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-300">Quick, Easy-To-Read Format</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-3">
                                        <svg className="w-4 h-4 text-primary" viewBox="0 0 24 24" strokeLinecap="round" strokeWidth="2">
                                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-300">Key Abilities & Achievements</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-3">
                                        <svg className="w-4 h-4 text-primary" viewBox="0 0 24 24" strokeLinecap="round" strokeWidth="2">
                                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-300">Focused & Relevant Information</p>
                                </li>
                            </ul>
                            {/* <button onClick={() => {navigate("/focused")}} type="button" className="block mx-auto bg-alternative hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center">
                                <span className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-[20px] mr-2 stroke-neutral items-center" viewBox="0 0 24 24"><g strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"><path d="M17 2h5v5M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6M13 11l8.5-8.5"/></g></svg>
                                    <span className="items-center">Open Portfolio</span>
                                </span>
                            </button> */}
                            <button type="button" className="block mx-auto cursor-not-allowed bg-base_40 hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center" disabled>
                                <span className="items-center">Coming Soon</span>
                            </button>
                        </div>
                        <div className="w-11/12 h-2 mx-auto bg-base_20 rounded-b-lg opacity-75" />
                        <div className="w-10/12 h-2 mx-auto bg-base_20 rounded-b-lg opacity-50" />
                    </div>
                    <div className="max-w-[400px] m-auto">
                        <div className="p-8 bg-base_20 rounded-lg">
                            <div className="mb-6 text-center">
                                <p className="text-2xl font-bold mb-5 font-secondary text-neutral">
                                    Talent Showcase Portfolio
                                </p>
                                <div className="flex items-center justify-center">
                                    <p className="mr-2 text-5xl font-semibold text-neutral lg:text-6xl">
                                        5
                                    </p>
                                    <p className="text-lg text-base_60">&asymp; minutes</p>
                                </div>
                            </div>
                            <ul className="my-8 space-y-2 font-alternative font-medium text-[16px]">
                                <li className="flex items-center">
                                    <div className="mr-3">
                                        <svg className="w-4 h-4 text-primary" viewBox="0 0 24 24" strokeLinecap="round" strokeWidth="2">
                                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-300">Comprehensive & Complete</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-3">
                                        <svg className="w-4 h-4 text-primary" viewBox="0 0 24 24" strokeLinecap="round" strokeWidth="2">
                                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-300">Detailed Projects Information</p>
                                </li>
                                <li className="flex items-center">
                                    <div className="mr-3">
                                        <svg className="w-4 h-4 text-primary" viewBox="0 0 24 24" strokeLinecap="round" strokeWidth="2">
                                            <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8" />
                                            <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor" />
                                        </svg>
                                    </div>
                                    <p className="font-medium text-gray-300">Work Approach & Personality</p>
                                </li>
                            </ul>
                            <button onClick={() => {navigate("/profile")}} type="button" className="block mx-auto bg-alternative hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center">
                                <span className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-[20px] mr-2 stroke-neutral items-center" viewBox="0 0 24 24"><g strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"><path d="M17 2h5v5M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6M13 11l8.5-8.5"/></g></svg>
                                    <span className="items-center">Open Portfolio</span>
                                </span>
                            </button>
                        </div>
                        <div className="w-11/12 h-2 mx-auto bg-base_20 rounded-b-lg opacity-75" />
                        <div className="w-10/12 h-2 mx-auto bg-base_20 rounded-b-lg opacity-50" />
                    </div>
                </div>
                <div className="max-w-screen-md mb-4 sm:mx-auto">
                    <div className="flex items-center mt-20 group lg:max-w-xl">
                        <div className="flex items-center justify-center w-16 h-16 rounded-full mx-3">
                            <img src={ArticleIcon} className="w-16 h-16" alt="" />
                        </div>
                        <h2 className="font-sans text-2xl font-bold leading-none tracking-tight md:text-4xl">
                            <span className="inline-block mb-2 font-alternative">Personal Blog</span>
                            <div className="h-[0.35rem] rounded-lg ml-auto duration-300 origin-left transform bg-primary scale scale-x-[0.3] group-hover:scale-x-100" />
                        </h2>
                    </div>
                    <p className="m-4 text-neutral font-semibold font-alternative md:text-lg">
                        Unveiling the mystical wonders of the digital realm, embark on a wizarding adventure to my blogging site where innovation is the ultimate sorcery.
                    </p>
                </div>
                <div className="my-10 text-center">
                    <a href="https://blog.maliktalha.me" class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-primary transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-base group">
                        <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-primary group-hover:h-full"></span>
                        <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                            <svg class="w-5 h-5 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                        </span>
                        <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                            <svg class="w-5 h-5 text-base" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                        </span>
                        <span class="relative w-full text-left font-alternative font-bold transition-colors duration-200 ease-in-out group-hover:text-base">Open Blog</span>
                    </a>
                </div>
            </div>
        </div>
    );
};