import { useEffect, useRef } from "react";

export default function Modal({title, content, display, setDisplay, actions=false}) {
    const modalRef = useRef(null);

    useEffect(() => {
        const clickOutside = (e) => {
            if(modalRef.current && !modalRef.current.contains(e.target)) {
                setDisplay(false);
            }
        }
        document.addEventListener('click', clickOutside, true);
        return () => {
            document.removeEventListener('click', clickOutside, true);
        };
        // eslint-disable-next-line
    }, []);
    return(
        <div id="defaultModal" aria-hidden="true" className={"fixed transition-all duration-500 ease-in-out top-0 left-0 right-0 z-50 w-full h-full p-4 overflow-x-hidden bg-base/75 md:inset-0 "+(display?"visible opacity-100":"invisible opacity-0")}>
            <div ref={modalRef} className="relative font-alternative w-full mx-auto my-20 max-w-2xl md:h-auto bg-base">
                <div className="relative rounded-lg px-4 shadow bg-base_20">
                    <div className="flex items-start justify-between py-4 border-b rounded-t border-base_40">
                        <h3 className="text-2xl font-semibold text-neutral">
                            {title}
                        </h3>
                        <button type="button" onClick={() => {setDisplay(false)}} className="text-base_60 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-base_40 hover:text-neutral" data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="px-4 py-6 space-y-6">
                        {content}
                    </div>
                    {actions && <div className="flex items-center p-6 space-x-2 border-t rounded-b border-base_40">
                        <button data-modal-hide="defaultModal" type="button" className="text-neutral focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">I accept</button>
                        <button data-modal-hide="defaultModal" type="button" className="focus:ring-4 focus:outline-none rounded-lg border text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:bg-gray-600 focus:ring-gray-600">Decline</button>
                    </div>}
                </div>
            </div>
        </div>
    );
}