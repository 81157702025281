import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ReactComponent as Image02 } from "../media/images/home_image_2.svg";
import InfoIcon from "../media/images/info_icon.png";

export default function HomeSection2() {
    const text = ["Designing", "Developing", "Maintaining"];
    const color = ["primary", "alternative", "secondary"];
	const [count, setCount] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if(count < 2) {
                setCount(prevCount => prevCount + 1);
            }
            else {
                setCount(0);
            }
            console.log("Count: ", count);
        }, 2500);
        return () => clearInterval(intervalId);
      }, [count]);
      return(
        <>
            <div className="flex items-center mt-16 md:mt-24  group lg:max-w-xl">
                <div className="flex items-center justify-center w-16 h-16 rounded-full mx-3">
                    <img src={InfoIcon} className="w-16 h-16" alt="" />
                </div>
                <h2 className="font-sans text-2xl font-bold leading-none tracking-tight md:text-4xl">
                    <span className="inline-block mb-2 font-alternative">My Strong Suit</span>
                    <div className="h-[0.35rem] rounded-lg ml-auto duration-300 origin-left transform bg-primary scale scale-x-[0.3] group-hover:scale-x-100" />
                </h2>
            </div>
            <div className="block md:flex flex-row content-center items-center m-5 md:m-10 md:mb-20">
                <div className="md:px-5 pb-10 md:pb-0">
                    <motion.div animate={{ scale: [0.99, 1.03, 0.99] }} transition={{ repeat: Infinity, duration: 2, type: "tween" }} className="mx-auto w-[220px] md:w-[280px]"><Image02 /></motion.div>
                </div>
                {/* Designing, developing, and maintaining */}
                <div className="md:px-5 mx-auto">
                    <h2 className="text-xl font-secondary text-center font-semibold md:leading-8 md:text-left md:text-2xl lg:leading-10 lg:text-3xl">I am capable of <span className="inline-flex overflow-hidden"><motion.span key={count} initial={{ y: -35, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.75 }} className={"inline-block w-32 md:w-36 lg:w-44 text-center text-"+color[count]}>{text[count]}</motion.span></span> efficient and reliable software solutions to improve business processes and user experience.</h2>
                </div>
            </div>
        </>
      );
}