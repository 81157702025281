import { useNavigate } from 'react-router-dom';
import NotFoundImg from "../media/images/error_404.svg";

export default function NotFound() {
    const navigate = useNavigate();
    return(
        <div className="w-full">
			<div className="min-h-[calc(100vh-75px)] max-w-[980px] my-[10px] mx-auto px-3 sm:my-6 ">
                <img className="block w-[300px] sm:w-auto sm:max-w-[500px] mx-auto my-5" alt="404 Not Found" src={NotFoundImg} />
                <h1 className="text-xl text-center m-3 font-secondary font-semibold">Looks like the page you're searching for is missing in action, just like a mischievous mouse that's been sneaking around this website. Don't worry though, I am actively trying to track that pesky fella down.</h1> 
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-5 sm:my-10">
                    <div className="bg-base_20 max-w-[450px] justify-self-center rounded-lg p-4">
                        <h1 className="text-center mb-3 font-semibold font-alternative">It seems you got lost, it's recommended to go back to the main profile page and start over.</h1>
                        <button onClick={() => navigate("/profile")} type="button" className="block mx-auto bg-alternative hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center">
                            <span className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-[20px] mr-2 fill-neutral items-center" viewBox="0 0 18 18"><g><path d="M5.02 4.51h-.05m.74 6.276a1 1 0 0 1 0 1.417.967.967 0 0 1-.69.28H5a1.22 1.22 0 0 1-.55-.17c-.01-.01-.14-.09-.16-.11L.4 9.287A1.017 1.017 0 0 1 0 8.5v-.008a1.04 1.04 0 0 1 .4-.8L4.29 4.78c.02-.02.15-.1.16-.11a1.1 1.1 0 0 1 .52-.17H5a1.017 1.017 0 0 1 .71.28 1 1 0 0 1 0 1.416l-.05.05-1.17 1.137A1.5 1.5 0 0 0 4 8.493 1.49 1.49 0 0 0 4.49 9.6l1.17 1.137z"/><path d="M18 12.5a1 1 0 0 1-1 1 1.023 1.023 0 0 1-.79-.38l-.04-.06A6.177 6.177 0 0 0 13 10.637a3.664 3.664 0 0 0-1-.15V11.5a.974.974 0 0 1-.55.868 1 1 0 0 1-1.05-.09l-4-2.993a1 1 0 0 1 0-1.6l4-2.993a.99.99 0 0 1 1.05-.09.986.986 0 0 1 .55.868V6.5c2.15 0 5.91 3.09 6 6z"/></g></svg>                                
                                <span className="items-center">Go Back</span>
                            </span>
                        </button>
                    </div>
                    <div className="bg-base_20 max-w-[450px] justify-self-center rounded-lg p-4">
                        <h1 className="text-center mb-3 font-semibold font-alternative">Wait <i>WHAT?</i> Have you found the mouse? Let me know about its location ASAP.</h1>
                        <button onClick={() => {navigate("/contact")}} type="button" className="block mx-auto bg-alternative hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center">
                            <span className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-[20px] mr-2 fill-neutral items-center" viewBox="0 0 18 18"><path d="M17.46.5a.662.662 0 0 0-.2.03C17.26.54.35 6.73.35 6.73a.578.578 0 0 0-.35.53.54.54 0 0 0 .26.47c.02.01 3.39 2.08 4.46 2.733a.6.6 0 0 1 .28.57c-.09.8-.28 2.59-.28 2.612a.56.56 0 0 0 .55.56l2.16-.01a.52.52 0 0 1 .45.276l1.64 2.776a.57.57 0 0 0 .47.255.53.53 0 0 0 .48-.3l7.46-15.9a.442.442 0 0 0 .07-.25.557.557 0 0 0-.54-.55zM6.97 11.85l.38-3.54L17.62.95z"/></svg>
                                <span className="items-center">Contact Me</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}