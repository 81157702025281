import { motion } from "framer-motion";
import { useState } from "react";
import emailjs from '@emailjs/browser';
import CaptchaModal from "../components/CaptchaModal";
import axios from "axios";

export default function Contact() {
    const [ name, setName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ subject, setSubject ] = useState("");
    const [ message, setMessage ] = useState("");
    const [ captcha, setCaptcha ] = useState("");
    const [ nameErr, setNameErr ] = useState(false);
    const [ emailErr, setEmailErr ] = useState(false);
    const [ subjectErr, setSubjectErr ] = useState(false);
    const [ messageErr, setMessageErr ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ captchaModal, setCaptchaModal ] = useState(false);
    const [ statusMsg, setStatusMsg ] = useState("");
    const getWordCount = (message) => {
        if(message === "") {
            return 0;
        }
        return message.trim().split(/\s+/).length;
    }
    const isValidEmail = (email) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };
    const verifyForm = () => {
        let status = true;
        if(name === "") {
            setNameErr(true);
            status = false;
            console.log("Invalid Name!");
        }
        if(email === "" || !isValidEmail(email)) {
            setEmail("");
            setEmailErr(true);
            status = false;
            console.log("Invalid Email!");
        }
        if(subject === "") {
            setSubjectErr(true);
            status = false;
            console.log("Invalid Subject!");
        }
        if(message === "" || getWordCount(message) < 10) {
            setMessageErr(true);
            status = false;
            console.log("Invalid Message!");
        }
        return status;
    }
    const remErrors = () => {
        if(name !== "") {
            setNameErr(false);
        }
        if(isValidEmail(email)) {
            setEmailErr(false);
        }
        if(subject !== "") {
            setSubjectErr(false);
        }
        if(message !== "" && getWordCount(message) >= 10) {
            setMessageErr(false);
        }   
    }
    const verifyCaptcha = () => {
        if(captcha === "" || captcha === null) {
            setCaptchaModal(true);
            return false;
        }
        return true;
    }
    const getUserLocation = async () => {
        const config = {
            headers:{
                Accept: 'application/json'
            }
          };
        const response = await axios.get(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_GEO_SITE_KEY}`, config)
                            .then(res => { return res })
                            .catch(err => { return {data: {city: "Unknown", country_name: "Unknown"}}});
        return response;
    }
    const submitForm = async () => {
        if(sending === false && verifyForm() === true && verifyCaptcha() === true) {
            setStatusMsg("");
            setSending(true);
            let userLocation = await getUserLocation();
            userLocation = userLocation.data["city"] + ", " + userLocation.data["country_name"];
            // Setup payload to send email
            const formData = {
                subject: subject,
                message: message,
                fromName: name,
                fromEmail: email,
                fromLocation: userLocation,
            }
            const payload = {
                ...formData,
                "g-recaptcha-response": captcha,
            }
            console.log(payload);
            // Send the email
            await emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, payload, process.env.REACT_APP_EMAILJS_PUBLIC_ID)
                    .then((result) => {
                            setStatusMsg(
                                <span className="text-center italic font-semibold text-green-500">Message sent successfully.</span>
                            );
                        }, (error) => {
                            setStatusMsg(
                                <span className="text-center italic font-semibold text-alternative">Failed to send message. Please try again.</span>
                            );
                            console.log(error);
                    });     
            // Reset input and error state
            setName("");
            setNameErr(false);
            setEmail("");
            setEmailErr(false);
            setSubject("");
            setSubjectErr(false);
            setMessage("");
            setMessageErr(false);
            setCaptcha("");
            setSending(false);
        }
    }
    return (
        <>
        <CaptchaModal captcha={captcha} setCaptcha={setCaptcha} display={captchaModal} setDisplay={setCaptchaModal} />
        <div className="md:bg-base_20">
        <motion.div className="flex min-h-[calc(100vh-85px)]" initial={{opacity: 0.3, y: 150}} animate={{opacity: 1, y:0}} transition={{  type: "tween", duration: 0.5 }}>
            <div className="flex flex-row w-full">
                <div className="hidden lg:flex items-center lg:p-8 lg:max-w-sm xl:max-w-lg">
                    <div className="space-y-5">
                        <h1 className="my-2 lg:text-3xl xl:text-5xl xl:leading-tight font-extrabold">Get in touch, great things await!</h1>
                        <p className="my-2 text-md md:text-lg font-medium font-alternative"><b>Got a burning question? A curious mind? Or just feeling a little lonely?</b><br/><br/>Let's chat and see what we can come up with together!</p>
                    </div>
                </div>
                <div className="flex bg-base px-2 rounded-none flex-1 flex-col items-center justify-center md:px-10 relative md:rounded-l-lg">
                    <div className="flex lg:hidden justify-center items-center w-full py-4">
                        <div className="space-y-5 text-center">
                            <h1 className="my-2 text-3xl leading-snug font-extrabold">Get in touch, great things await!</h1>
                        </div>
                    </div>
                    <form>
                    <div className="flex flex-1 flex-col justify-center space-y-8 mt-8 max-w-md">
                        <div className="flex flex-col space-y-2 text-center">
                            <h2 className="text-3xl md:text-4xl font-bold">Contact Me</h2>
                        </div>
                        <div className="flex flex-col max-w-md space-y-5">
                            <input type="text" value={name} onChange={(e) => {setName(e.target.value); remErrors();}} autoComplete="none" placeholder={(nameErr?"Enter a valid name":"Name")} className={"flex px-3 py-2 min-w-[280px] md:px-4 md:py-3 outline-none text-neutral bg-base_20 ring-base_40 focus:ring-2 rounded-lg font-medium placeholder:text-[#999] placeholder:font-semibold " + (nameErr?"ring-alternative ring-2":"")} />
                            <input type="email" value={email} onChange={(e) => {setEmail(e.target.value); remErrors();}} autoComplete="none" placeholder={(emailErr?"Enter a valid email":"Email")} className={"flex px-3 py-2 md:px-4 md:py-3 outline-none text-neutral bg-base_20 ring-base_40 focus:ring-2 rounded-lg font-medium placeholder:text-[#999] placeholder:font-semibold " + (emailErr?"ring-alternative ring-2":"")} />
                            <select id="contactSubject" value={subject} onChange={(e) => {setSubject(e.target.value); remErrors();}} className={"flex px-3 py-2 md:px-4 md:py-3 outline-none cursor-pointer border-r-[16px] border-transparent text-neutral bg-base_20 ring-base_40 focus:bg-base_20 focus:ring-2 rounded-lg font-medium " + (subjectErr?"ring-alternative ring-2":"")}>
                                <option className="bg-base" value="" selected disabled>Choose Subject</option>
                                <option className="bg-base" value="Job Inquiry">Job Inquiry</option>
                                <option className="bg-base" value="Project Proposal">Project Proposal</option>
                                <option className="bg-base" value="Consultation">Consultation</option>
                                <option className="bg-base" value="General Question">General Question</option>
                                <option className="bg-base" value="Feeling Bored">Feeling Bored</option>
                                <option className="bg-base" value="Other">Other</option>
                            </select>
                            <div className="">
                                <textarea rows={4} value={message} onChange={(e) => {setMessage(e.target.value); remErrors();}} autoComplete="none" placeholder={(messageErr?"Enter a valid message":"Message")} className={"block px-3 py-2 md:px-4 md:py-3 w-full outline-none text-neutral bg-base_20 ring-base_40 focus:ring-2 rounded-lg font-medium placeholder:text-[#999] placeholder:font-semibold " + (messageErr?"ring-alternative ring-2":"")} ></textarea>
                                <div className="block w-[280px] text-xs text-right font-semibold pt-1"><i>{messageErr?"Message should have a minimum of 10 words":getWordCount(message)+" words"}</i></div>
                            </div>
                            {
                                captcha !== "" &&
                                <button type="button" onClick={() => {submitForm()}} className="flex bg-green-600 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center cursor-default">
                                    <span className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[20px] mr-2 fill-neutral items-center stroke-neutral" strokeWidth="1.05" viewBox="0 0 35 35"><path d="M17.5 34.44a3.07 3.07 0 0 1-1.61-.44l-6.07-3.55A14.79 14.79 0 0 1 2.25 17.7V8a3.2 3.2 0 0 1 2.09-3L16.4.57a3.2 3.2 0 0 1 2.2 0L30.66 5a3.2 3.2 0 0 1 2.09 3v9.7a14.79 14.79 0 0 1-7.57 12.75L19.11 34a3.07 3.07 0 0 1-1.61.44Zm0-31.56a.67.67 0 0 0-.24 0L5.2 7.33a.69.69 0 0 0-.45.67v9.7a12.3 12.3 0 0 0 6.33 10.59l6.07 3.56a.73.73 0 0 0 .7 0l6.07-3.56a12.3 12.3 0 0 0 6.33-10.59V8a.69.69 0 0 0-.45-.65L17.74 2.92a.67.67 0 0 0-.24-.04Z"/><path d="M16.4 22.35a1.3 1.3 0 0 1-.81-.29l-4.27-3.6a1.25 1.25 0 0 1 1.61-1.92l3.35 2.82 5.72-6.3a1.25 1.25 0 0 1 1.86 1.68l-6.48 7.2a1.27 1.27 0 0 1-.98.41Z"/></svg>           
                                        <span className="items-center">Human Verified</span>
                                    </span>
                                </button>
                            }                           
                            <button type="button" onClick={() => {submitForm()}} className={"block mx-auto bg-alternative hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center " + (sending?"bg-opacity-70 cursor-not-allowed":"")}>
                                <span className="flex items-center">
                                    {
                                        sending?
                                        <svg aria-hidden="true" className="w-[20px] h-[20px] mr-2 text-neutral animate-spin items-center fill-base" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[20px] mr-2 fill-neutral items-center" viewBox="0 0 18 18"><path d="M17.46.5a.662.662 0 0 0-.2.03C17.26.54.35 6.73.35 6.73a.578.578 0 0 0-.35.53.54.54 0 0 0 .26.47c.02.01 3.39 2.08 4.46 2.733a.6.6 0 0 1 .28.57c-.09.8-.28 2.59-.28 2.612a.56.56 0 0 0 .55.56l2.16-.01a.52.52 0 0 1 .45.276l1.64 2.776a.57.57 0 0 0 .47.255.53.53 0 0 0 .48-.3l7.46-15.9a.442.442 0 0 0 .07-.25.557.557 0 0 0-.54-.55zM6.97 11.85l.38-3.54L17.62.95z"/></svg>
                                    }                
                                    <span className="items-center">{sending?"Sending ...":"Send Message"}</span>
                                </span>
                            </button>
                            {statusMsg}
                        </div>
                    </div>
                    </form>
                    <div className="flex justify-center flex-col m-auto my-10 text-center text-md">
                        <p className="font-alternative font-semibold">Want to get more personal? Hit me up on the following social networks.</p>
                        <div className="flex items-center justify-center space-x-2 mt-1 flex-wrap">
                            <a href="https://www.linkedin.com/in/malik-talha-s" className="flex flex-none items-center justify-center rounded-full w-12 h-12 hover:bg-base_20 transition-all">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="4.983" cy="5.009" r="2.188" fill="currentColor"></circle>
                                    <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z" fill="currentColor"></path>
                                </svg>
                            </a>
                            <a href="https://github.com/malik727" className="flex flex-none items-center justify-center rounded-full w-12 h-12 hover:bg-base_20 transition-all">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z" fill="currentColor"></path>
                                </svg>
                            </a>
                            <a href="https://twitter.com/malik_talha_s" className="flex flex-none items-center justify-center rounded-full w-12 h-12 hover:bg-base_20 transition-all">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" fill="currentColor"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
        </div>
        </>
    );
}