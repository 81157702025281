import SkillsIcon from "../media/images/skills_icon.png";
import JSIcon from "../media/images/js_logo.png";
import CSSIcon from "../media/images/css_logo.png";
import HTMLIcon from "../media/images/html_logo.png";
import MySQLIcon from "../media/images/mysql_logo.png";
import CPPIcon from "../media/images/cpp_logo.png";
import DjangoIcon from "../media/images/django_logo.png";
import ReactIcon from "../media/images/react_logo.png";
import NodeIcon from "../media/images/node_logo.png";
import PythonIcon from "../media/images/python_logo.png";
import PHPIcon from "../media/images/php_logo.png";
import GitIcon from "../media/images/git_logo.png";
import LinuxIcon from "../media/images/linux_logo.png";
import JQueryIcon from "../media/images/jquery_logo.png";
import DockerIcon from "../media/images/docker_logo.png";
import NextJSIcon from "../media/images/nextjs_logo.png";
import TerraformIcon from "../media/images/terraform_logo.png";
import KubernetesIcon from "../media/images/kubernetes_logo.png";
import AzureIcon from "../media/images/azure_logo.png";
import FlutterIcon from "../media/images/flutter_logo.png";
import YoctoIcon from "../media/images/yocto_logo.png";
import gRPCIcon from "../media/images/grpc_logo.png";


const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort( () => .5 - Math.random() );

function InfiniteLoopSlider({children, duration, reverse = false}){
  return (
    <div className="loop-slider" style={{
        '--duration': `${duration}ms`,
        '--direction': reverse ? 'reverse' : 'normal'
      }}>
        <div className="flex w-fit slide-elems">
            {children}
            {children}
        </div>
    </div>
  );
};

function SlideElement({ logo, text }){ 
    return (
        <div className="flex items-center gap-x-1 rounded-full px-4 py-2 mr-4 bg-base_20 text-neutral text-[14px] font-alternative font-semibold">
            <span className="flex">    
                <img src={logo} className="w-5 h-5 items-center" alt="" />
                <span className="items-center pl-1 pr-6">{text}</span>
            </span>
        </div>
    );
};


export default function HomeSection3() {
    const elements = ['HTML', 'CSS', 'JavaScript', 'Django', 'PHP', 'ReactJS', 'MySQL', 'C/C++', 'Python', 'NodeJS', 'Git', 'Docker', 'JQuery', 'Linux', 'NextJS', 'Yocto', 'gRPC', 'Azure', 'Kubernetes', 'Terraform', 'Flutter'];
    const logo_icons = [HTMLIcon, CSSIcon, JSIcon, DjangoIcon, PHPIcon, ReactIcon, MySQLIcon, CPPIcon, PythonIcon, NodeIcon, GitIcon, DockerIcon, JQueryIcon, LinuxIcon, NextJSIcon, YoctoIcon, gRPCIcon, AzureIcon, KubernetesIcon, TerraformIcon, FlutterIcon];
    const logos = Object.assign(...elements.map((k, i) => ({ [k]: logo_icons[i] })));
    const anim_duration = 22500;
    const elem_rows = 3;
    const elem_per_row = 7;
    return(
        <>
            <div className="flex items-center mt-16 md:mt-24 group lg:max-w-xl">
                <div className="flex items-center justify-center w-16 h-16 rounded-full mx-1 md:mx-3">
                    <img src={SkillsIcon} className="w-16 h-16" alt="" />
                </div>
                <h2 className="font-sans text-2xl font-bold leading-none tracking-tight md:text-4xl">
                    <span className="inline-block mb-2 font-alternative">My Expertise</span>
                    <div className="h-[0.35rem] rounded-lg ml-auto duration-300 origin-left transform bg-primary scale scale-x-[0.3] group-hover:scale-x-100" />
                </h2>
            </div>
            <div className="block md:flex flex-row content-center items-center m-5 md:m-10 md:mb-20">
                <div className="md:px-5">
                    <h2 className="text-xl text-center font-secondary font-semibold md:text-left md:leading-8 md:text-2xl lg:leading-10 lg:text-3xl">I am skilled in multiple languages and frameworks, ready to cast some magic and deliver exceptional solutions.</h2>
                </div>
                <div className="md:px-5 ">
                    <div className="relative mx-auto flex flex-shrink-0 flex-col gap-y-4 overflow-hidden py-6 w-72 md:py-0 md:w-[24rem] lg:w-[30rem]">
                        {[...new Array(elem_rows)].map((_, i) => (
                            <InfiniteLoopSlider key={i} duration={random(anim_duration - 5000, anim_duration + 5000)} reverse={i % 2}>
                                {
                                    shuffle(elements).slice(0, elem_per_row).map((tag, j) => (
                                        <SlideElement logo={logos[tag]} text={tag} key={tag}/>
                                    ))
                                }
                            </InfiniteLoopSlider>
                        ))}
                        <div className="fade_effect" />
                    </div>
                </div>
            </div>
        </>
    );
}