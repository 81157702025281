import Typical from "../components/Typical";
import LandingSVG from "../components/LandingSVG";
import HomeSection2 from "../components/HomeSection2";
import HomeSection3 from "../components/HomeSection3";
import GitHubIcon from "../media/images/github_logo.png";
import LinkedInIcon from "../media/images/linkedin_logo.png";
import TwitterIcon from "../media/images/twitter_logo.png";
import { motion } from "framer-motion";

export default function Profile() {
    return (
		<>
			<motion.div className="mx-[10px] my-[20px] md:my-0 md:mx-10 lg:mx-15 xl:mx-20" initial={{opacity: 0.3, y: 150}} animate={{opacity: 1, y:0}} transition={{ type: "tween", duration: 0.5 }}>
				<div className="min-h-[calc(100vh-75px)] grid grid-cols-1 md:grid-cols-2 md:gap-4">
					<div className="self-center justify-self-center md:justify-self-start md:py-8 md:pr-4">
						<div className="flex text-4xl justify-center md:justify-start lg:text-5xl xl:text-6xl font-main whitespace-nowrap">
							<p className="wave px-2">&#128075;</p>
							<p>
								<Typical
									strings={[
										"Hello!", 
										"Bonjour!",
										"Hola!",
										"Salve!", 
										"Olá!",
										"Guten Tag!",
										"Kon'nichiwa!"
									]}
									typeSpeed={75}
									backSpeed={75}
									backDelay={1250}
									cursorChar={"_"}
									fadeOut={true}
									loop
								/>
							</p>
						</div>
						<div className="p-5 text-center md:text-left md:pr-0 xl:pl-10 font-secondary text-2xl lg:text-3xl">
							<h1>I am <span className="text-primary font-bold">Malik Talha</span>, a wizard of the <span className="text-secondary font-bold">Software Engineering</span> realm.</h1>
						</div>
						<div className="text-center md:text-left lg:ml-5">
							<a href="https://linkedin.com/in/malik-talha-s" className="m-2" rel="noreferrer" target="_blank"><button type="button" className="inline-block text-[16px] transition-all duration-300 ease-in-out border-alternative border-[3px] hover:bg-opacity-70 focus:outline-none font-semibold rounded-full font-alternative px-4 py-2 text-center hover:bg-alternative/40">
								<span className="flex">
									<img src={LinkedInIcon} className="w-6 h-6 mr-2 items-center" alt="" />
									<span className="items-center">LinkedIn</span>
								</span>
							</button></a>
							<a href="https://github.com/malik727" className="m-2" rel="noreferrer" target="_blank"><button type="button" className="inline-block text-[16px] transition-all duration-300 ease-in-out border-alternative border-[3px] hover:bg-opacity-70 focus:outline-none font-semibold rounded-full font-alternative px-4 py-2 text-center hover:bg-alternative/40">
								<span className="flex">
									<img src={GitHubIcon} className="w-6 h-6 mr-2 items-center" alt="" />
									<span className="items-center">GitHub</span>
								</span>
							</button></a>
							<a href="https://twitter.com/malik_talha_s" className="m-2" rel="noreferrer" target="_blank"><button type="button" className="inline-block text-[16px] transition-all duration-300 ease-in-out border-alternative border-[3px] hover:bg-opacity-70 focus:outline-none font-semibold rounded-full font-alternative px-4 py-2 text-center hover:bg-alternative/40">
								<span className="flex">
									<img src={TwitterIcon} className="w-6 h-6 mr-2 items-center" alt="" />
									<span className="items-center">Twitter</span>
								</span>
							</button></a>
						</div>
					</div>
					<div className="self-center justify-self-center md:justify-self-end md:pt-8 md:pb-2 md:pl-4 md:pr-5 xl:pr-10">
						<div className="w-[280px] md:w-[320px] lg:w-[460px] xl:w-[500px]">
							<LandingSVG />
						</div>
					</div>
					<div className="self-center justify-self-center md:col-span-2">
						<div className="scrolldown">
							<div className="chevrons">
								<div className="chevrondown"></div>
								<div className="chevrondown"></div>
							</div>
						</div>
					</div>
				</div>
				<HomeSection2 /><br/>
				<HomeSection3 />
			</motion.div>
		</>
	);
}