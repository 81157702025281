import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import Router from "./Router";

function App() {
	useEffect(() => {
		const loader = document.querySelector('#loading');
		loader.classList.add('hide');
	});
	return (
		<main>
			<RouterProvider router={Router} />
		</main>
	);
}

export default App;
