import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

export default function Root() {
    return (
        <>
            <ScrollToTop />
            <Navigation />
            <Outlet />
            <Footer />
        </>
    );
};