import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './media/css/main.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

setTimeout(() => 
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
), 1500);

reportWebVitals();
