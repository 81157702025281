import { motion } from "framer-motion";
import { useState } from "react";

export default function Experience() {
    const [accordion1, setAccordion1] = useState(false);
    const [accordion2, setAccordion2] = useState(false);
    const [accordion3, setAccordion3] = useState(false);
    const [accordion4, setAccordion4] = useState(false);
    const [accordion5, setAccordion5] = useState(false);
    return(
        <motion.div className="min-h-[calc(100vh-75px)]" initial={{opacity: 0.3, y: 150}} animate={{opacity: 1, y:0}} transition={{ type: "tween", duration: 0.5 }}>
            <div className="container max-w-5xl px-2 py-12 mx-auto">
                <div className="grid gap-4 mx-2 sm:mx-4 sm:grid-cols-12">
                    <div className="col-span-12 sm:col-span-3">
                        <div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-primary">
                            <h3 className="text-3xl font-semibold font-secondary">Professional Experience</h3>
                        </div>
                    </div>
                    <div className="relative col-span-12 sm:px-4 space-y-6 sm:col-span-9">
                        <div className="col-span-12 space-y-12 font-alternative relative sm:px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-base_20">
                            
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-alternative">
                                <h3 className="text-xl font-semibold tracking-wide">Associate Engineer</h3>
                                <h4 className="text-sm">Zones, LLC</h4>
                                <time className="text-xs tracking-wide uppercase text-base_60">Jul 2023 - Present</time>
                                <p className="mt-2 font-medium">Zones is a Global Solution Provider of end-to-end IT solutions with an unmatched supply chain.</p>
                                <div onClick={() => {setAccordion1(!accordion1)}} className={"m-2 transition-all ease-in-out duration-500 overflow-hidden bg-base_20 border rounded-lg border-base_20 "+(accordion1===true?"max-h-[520px] sm:max-h-[400px] md:max-h-[300px]":"max-h-[70px]")} >
                                    <h2>
                                        <button type="button" className="flex items-center justify-between w-full p-4 font-semibold text-left">
                                            <span>Responsiblities</span>
                                            <svg className={"w-6 h-6 transition-all ease-in-out duration-300 shrink-0 "+(accordion1===true?"rotate-180":"")} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                    </h2>
                                    <div className={"transition-all ease-in-out duration-500 "+(accordion1===true?"opacity-100":"opacity-0 h-0")}>
                                        <ul className="list-inside list-disc m-6 mt-0 text-sm space-y-2">
                                            <li>Engineered scalable data pipelines for high-speed processing of millions of records, improving data throughput by 65%.</li>
                                            <li>Automated project infrastructure and deployment processes, reducing delivery times from development to production.</li>
                                            <li>Developed backend APIs with endpoints for seamless data transfers and communications with Data Factory pipelines and Azure cloud.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-alternative">
                                <h3 className="text-xl font-semibold tracking-wide">Software Developer</h3>
                                <h4 className="text-sm">The Linux Foundation</h4>
                                <time className="text-xs tracking-wide uppercase text-base_60">May 2023 - Nov 2023</time>
                                <p className="mt-2 font-medium">Google Summer of Code (GSoC) 2023 contributor for AGL (Automotive Grade Linux), a collaborative open source project by The Linux Foundation.</p>
                                <div onClick={() => {setAccordion2(!accordion2)}} className={"m-2 transition-all ease-in-out duration-500 overflow-hidden bg-base_20 border rounded-lg border-base_20 "+(accordion2===true?"max-h-[540px] sm:max-h-[420px] md:max-h-[320px]":"max-h-[70px]")} >
                                    <h2>
                                        <button type="button" className="flex items-center justify-between w-full p-4 font-semibold text-left">
                                            <span>Responsiblities</span>
                                            <svg className={"w-6 h-6 transition-all ease-in-out duration-300 shrink-0 "+(accordion2===true?"rotate-180":"")} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                    </h2>
                                    <div className={"transition-all ease-in-out duration-500 "+(accordion2===true?"opacity-100":"opacity-0 h-0")}>
                                        <ul className="list-inside list-disc m-6 mt-0 text-sm space-y-2">
                                            <li>Conceptualized, designed, and implemented a comprehensive Voice Assistant for Automotive Grade Linux (AGL). Leveraged GStreamer, gRPC, Vosk, Snips, and RASA to process user's voice commands into text, extract intents, and execute actions through the Kuksa interface.</li>
                                            <li>Crafted a modular and extensible voice agent background service that integrates multiple speech recognition and intent extraction models. Achieved seamless command interpretation, enabling easy addition of new models for diverse functionalities.</li>
                                            <li>Designed and implemented a user-friendly Flutter-based IVI application for Automotive Grade Linux (AGL). This app facilitated seamless interaction with the voice agent service, enabling users to command, receive, and visualize responses.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-alternative">
                                <h3 className="text-xl font-semibold tracking-wide">Software Engineering Intern</h3>
                                <h4 className="text-sm">Askari Bank Ltd.</h4>
                                <time className="text-xs tracking-wide uppercase text-base_60">Aug 2021 - Sep 2021</time>
                                <p className="mt-2 font-medium">Askari Bank Ltd. is one of the leading banks in Pakistan providing its services to over 10 million customers worldwide.</p>
                                <div onClick={() => {setAccordion3(!accordion3)}} className={"m-2 transition-all ease-in-out duration-500 overflow-hidden bg-base_20 border rounded-lg border-base_20 "+(accordion3===true?"max-h-[520px] sm:max-h-[400px] md:max-h-[300px]":"max-h-[70px]")} >
                                    <h2>
                                        <button type="button" className="flex items-center justify-between w-full p-4 font-semibold text-left">
                                            <span>Responsiblities</span>
                                            <svg className={"w-6 h-6 transition-all ease-in-out duration-300 shrink-0 "+(accordion3===true?"rotate-180":"")} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                    </h2>
                                    <div className={"transition-all ease-in-out duration-500 "+(accordion3===true?"opacity-100":"opacity-0 h-0")}>
                                        <ul className="list-inside list-disc m-6 mt-0 text-sm space-y-2">
                                            <li>Developed a full-stack application to automate the creation and assignment of business and feature requirement documents, reducing application development time by almost 20%.</li>
                                            <li>Developed new features, fixed bugs, and improved the overall performance of existing projects, fixing around 5% of the total bugs.</li>
                                            <li>Participated in various workshops to enhance my skills in topics such as agile development and full-stack development, increasing my overall knowledge and understanding of software engineering practices.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <hr className="block w-60 h-1 my-16 mx-auto border-0 rounded-lg md:my-24 bg-base md:bg-base_40" />
                </div>
                <div className="grid gap-4 mx-2 sm:mx-4 sm:grid-cols-12">
                    <div className="col-span-12 sm:col-span-3">
                        <div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-primary">
                            <h3 className="text-3xl font-semibold font-secondary">Co-Curricular Experience</h3>
                            {/* <span className="text-sm font-bold tracking-wider uppercase text-gray-400">Vestibulum diam nunc</span> */}
                        </div>
                    </div>
                    <div className="relative col-span-12 sm:px-4 space-y-6 sm:col-span-9">
                        <div className="col-span-12 space-y-12 font-alternative relative sm:px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-base_20">
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-alternative">
                                <h3 className="text-xl font-semibold tracking-wide">Chairperson </h3>
                                <h4 className="text-sm">FAST Developer Community</h4>
                                <time className="text-xs tracking-wide uppercase text-base_60">Nov 2021 - Aug 2022</time>
                                <p className="mt-2 font-medium">FDC is a student-led organization that utilizes research and development to address real-world technological challenges, under the guidance of university faculty members.</p>
                                <div onClick={() => {setAccordion4(!accordion4)}} className={"m-2 transition-all ease-in-out duration-500 overflow-hidden bg-base_20 border rounded-lg border-base_20 "+(accordion4===true?"max-h-[520px] sm:max-h-[400px] md:max-h-[300px]":"max-h-[70px]")} >
                                    <h2>
                                        <button type="button" className="flex items-center justify-between w-full p-4 font-semibold text-left">
                                            <span>Responsiblities</span>
                                            <svg className={"w-6 h-6 transition-all ease-in-out duration-300 shrink-0 "+(accordion4===true?"rotate-180":"")} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                    </h2>
                                    <div className={"transition-all ease-in-out duration-500 "+(accordion4===true?"opacity-100":"opacity-0 h-0")}>
                                        <ul className="list-inside list-disc m-6 mt-0 text-sm space-y-2">
                                            <li>Designed and developed a secure single sign-on system enabling one account to securely connect with multiple web and mobile services, resulting in a 38% reduction in login issues, and a 45% improvement in user satisfaction.</li>
                                            <li>Mentored and coached multiple developers resulting in an average of 24% increase in technical skills and a 17% improvement in team efficiency.</li>
                                            <li>Successfully established the infrastructure and played a crucial role in developing a sophisticated web portal for students, allowing them to upload and efficiently manage their projects.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-alternative">
                                <h3 className="text-xl font-semibold tracking-wide">Team Lead</h3>
                                <h4 className="text-sm">Google Developer Student Clubs (GDSC)</h4>
                                <time className="text-xs tracking-wide uppercase text-base_60">Oct 2021 - May 2022</time>
                                <p className="mt-2 font-medium">GDSC is an empowering community of tech enthusiasts and aspiring developers. As an official program supported by Google, GDSC provides a platform for students to learn, collaborate, and build impactful projects.</p>
                                <div onClick={() => {setAccordion5(!accordion5)}} className={"m-2 transition-all ease-in-out duration-500 overflow-hidden bg-base_20 border rounded-lg border-base_20 "+(accordion5===true?"max-h-[520px] sm:max-h-[400px] md:max-h-[300px]":"max-h-[70px]")} >
                                    <h2>
                                        <button type="button" className="flex items-center justify-between w-full p-4 font-semibold text-left">
                                            <span>Responsiblities</span>
                                            <svg className={"w-6 h-6 transition-all ease-in-out duration-300 shrink-0 "+(accordion5===true?"rotate-180":"")} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                    </h2>
                                    <div className={"transition-all ease-in-out duration-500 "+(accordion5===true?"opacity-100":"opacity-0 h-0")}>
                                        <ul className="list-inside list-disc m-6 mt-0 text-sm space-y-2">
                                            <li>Headed the web development team.</li>
                                            <li>Contributed to the core team by enhancing engagement and productivity.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <hr className="block w-60 h-1 my-16 mx-auto border-0 rounded-lg md:my-24 bg-base md:bg-base_40" />
                </div>
                <div className="grid gap-4 mx-2 sm:mx-4 sm:grid-cols-12">
                    <div className="col-span-12 sm:col-span-3">
                        <div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-primary">
                            <h3 className="text-3xl font-semibold font-secondary">Education</h3>
                            {/* <span className="text-sm font-bold tracking-wider uppercase text-gray-400">Vestibulum diam nunc</span> */}
                        </div>
                    </div>
                    <div className="relative col-span-12 sm:px-4 space-y-6 sm:col-span-9">
                        <div className="col-span-12 space-y-12 font-alternative relative sm:px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-base_20">
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-alternative">
                                <h3 className="text-xl font-semibold tracking-wide">B.Sc. Computer Science</h3>
                                <h4 className="text-sm">Islamabad, Pakistan</h4>
                                <time className="text-xs tracking-wide uppercase text-base_60">Aug 2019 - Jun 2023</time>
                                <p className="mt-2 font-semibold">National University of Computer & Emerging Sciences (FAST NUCES)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}