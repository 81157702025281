import { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFindPath } from "./FindPath";
import MainLogo from "../media/images/main_logo.png";
import Modal from "./Modal";
import GitHubIcon from "../media/images/github_logo.png";

export default function Navigation() {
    const navigate = useNavigate();
    const currentPath = useFindPath();
    const menuRef = useRef(null);
    const openMenuRef = useRef(null);
    const [ mobMenu, setMobMenu ] = useState(false);
    const [ currentMenu, setCurrentMenu ] = useState(0);
    const [ projectsModal, setProjectsModal ] = useState(false);
	const [ modalHeading, setModalHeading ] = useState("");
	const [ modalContent, setModalContent ] = useState(<></>);
	const openProjectsModal = () => {
		setModalHeading("Projects");
		setModalContent(
            <>
                <h1 className="font-semibold text-center">A fully detailed project section is coming soon. Your patience is highly appreciated. In the meantime I recommend checking out my GitHub profile.</h1>
                <div className="text-center mt-2">
                    <a href="https://github.com/malik727" className="inline-block" rel="noreferrer" target="_blank">
                        <button type="button" className="block text-[16px] mx-auto transition-all duration-300 ease-in-out bg-alternative border-alternative border-[3px] hover:bg-opacity-70 focus:outline-none font-semibold rounded-full font-alternative px-4 py-2 text-center hover:bg-alternative/40">
                            <span className="flex">
                                <img src={GitHubIcon} className="w-6 h-6 mr-1 items-center" alt="" />
                                <span className="items-center">GitHub</span>
                            </span>
                        </button>
                    </a>
                </div>
            </>
        );
		setProjectsModal(true);
	}
    const clickOutside = (e) => {
        if(menuRef.current && !menuRef.current.contains(e.target) && !openMenuRef.current.contains(e.target)) {
            setMobMenu(false);
        }
    }
    useEffect(() => {
        if(currentPath === "/profile" || currentPath === "/") {
            setCurrentMenu(1);
        }
        else if(currentPath === "/experience") {
            setCurrentMenu(2);
        }
        else if(currentPath === "/contact") {
            setCurrentMenu(3);
        }
    }, [currentPath]);
    useEffect(() => {
        document.addEventListener('click', clickOutside, true);
        return () => {
            document.removeEventListener('click', clickOutside, true);
        };
    }, []);
    return(
        <>
        <Modal title={modalHeading} content={modalContent} display={projectsModal} setDisplay={setProjectsModal} />
        <nav className="bg-base_20 px-2 sm:px-4 py-2.5">
            <div className="container min-h-[55px] overflow-hidden flex flex-wrap  items-center justify-between mx-auto md:overflow-auto">
                <a href="https://maliktalha.me/profile" className="flex items-center">
                    <img src={MainLogo} className="h-6 mr-3 sm:h-9" alt="Main Logo" />
                    <span className="self-center text-xl font-bold whitespace-nowrap font-alternative">Malik</span>
                </a>
                <div className="flex md:order-2">
                    <button type="button" onClick={openProjectsModal} className="bg-alternative hover:bg-opacity-70 focus:outline-none font-bold rounded-lg font-alternative px-5 py-2.5 text-center mr-3 md:mr-0">
                        <span className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-[20px] stroke-neutral mr-2 items-center" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 16 16"><path d="M1.75 11 8 14.25 14.25 11M1.75 8 8 11.25 14.25 8M8 1.75 1.75 5 8 8.25 14.25 5z"/></svg>
                            <span className="items-center">My Projects</span>
                        </span>
                    </button>
                    <button data-collapse-toggle="navbar-cta" ref={openMenuRef} onClick={() => {setMobMenu(!mobMenu)}} type="button" className="inline-flex items-center p-2 text-sm rounded-lg md:hidden focus:outline-none hover:bg-base_40 focus:ring-base_40" aria-controls="navbar-cta" aria-expanded="false">
                        <span className="sr-only">Open Menu</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
                <div ref={menuRef} className={"items-center justify-between w-full md:transition-none md:w-auto md:order-1 md:max-h-auto " + (mobMenu?"max-h-[175px] md:hidden duration-500 transition-all":"max-h-0 transition-none md:flex")} id="navbar-cta">
                    {/* <ClickOutsideListener handler={setMobMenu}> */}
                        <ul className="flex flex-col px-4 py-3 mt-4 rounded-lg text-base font-bold font-alternative md:flex-row md:space-x-8 md:mt-0 bg-[#121212]">
                            <li>
                                <Link onClick={(e) => {e.preventDefault(); setMobMenu(false); navigate("/profile");}} className={"block group py-2 pl-3 pr-4 text-neutral rounded hover:bg-base_20 md:p-0 md:hover:text-primary md:hover:bg-transparent md:transition-all md:duration-300 md:ease-in-out " + (currentMenu === 1?"bg-base_20 md:bg-base md:text-primary":"")} >
                                    <span className="flex md:bg-left-bottom md:bg-gradient-to-r md:from-primary md:to-primary md:bg-[length:0%_3px] md:bg-no-repeat md:group-hover:bg-[length:100%_3px] md:transition-all md:duration-500 md:ease-out md:py-[3px]">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="group w-[20px] mr-1 fill-neutral md:group-hover:fill-alternative md:transition-all md:duration-300 md:ease-in-out" viewBox="0 0 24 24"><path fill-rule="nonzero" d="M19.75 4A2.25 2.25 0 0 1 22 6.25v11.505a2.25 2.25 0 0 1-2.25 2.25H4.25A2.25 2.25 0 0 1 2 17.755V6.25A2.25 2.25 0 0 1 4.25 4h15.5Zm0 1.5H4.25a.75.75 0 0 0-.75.75v11.505c0 .414.336.75.75.75h15.5a.75.75 0 0 0 .75-.75V6.25a.75.75 0 0 0-.75-.75Zm-10 7a.75.75 0 0 1 .75.75v.493l-.008.108c-.163 1.113-1.094 1.65-2.492 1.65s-2.33-.537-2.492-1.65l-.008-.11v-.491a.75.75 0 0 1 .75-.75h3.5Zm3.502.496h4.498a.75.75 0 0 1 .102 1.493l-.102.007h-4.498a.75.75 0 0 1-.101-1.493l.101-.007h4.498-4.498ZM8 8.502a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm5.252.998h4.498a.75.75 0 0 1 .102 1.493L17.75 11h-4.498a.75.75 0 0 1-.101-1.493l.101-.007h4.498-4.498Z"/></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"group w-[14px] mr-1 fill-neutral md:group-hover:fill-primary md:transition-all md:duration-300 md:ease-in-out " + (currentMenu === 1?"md:fill-primary":"")} viewBox="0 0 18 18"><path d="M16 0H2a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V2a2.006 2.006 0 0 0-2-2zm0 16h-2v-.86A3.154 3.154 0 0 0 10.85 12h-.53a4.167 4.167 0 0 1 .85-2 4.007 4.007 0 0 0 .77-2.41C11.94 5.61 10.62 4 9 4S6.06 5.61 6.06 7.59A4.007 4.007 0 0 0 6.83 10a4.167 4.167 0 0 1 .85 2h-.57A3.126 3.126 0 0 0 4 15.14V16H2V2h14v14z"/></svg>
                                        <span>Profile</span>
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={(e) => {e.preventDefault(); setMobMenu(false); navigate("/experience");}} className={"block group py-2 pl-3 pr-4 text-neutral rounded hover:bg-base_20 md:p-0 md:hover:text-primary md:hover:bg-transparent md:transition-all md:duration-300 md:ease-in-out " + (currentMenu === 2?"bg-base_20 md:bg-base md:text-primary":"")}>
                                    <span className="flex md:bg-left-bottom md:bg-gradient-to-r md:from-primary md:to-primary md:bg-[length:0%_3px] md:bg-no-repeat md:group-hover:bg-[length:100%_3px] md:transition-all md:duration-500 md:ease-out md:py-[3px]">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="group w-[14px] mr-1 fill-neutral md:group-hover:fill-alternative md:transition-all md:duration-300 md:ease-in-out" viewBox="0 0 18 18"><g><path d="M9.31 9.45A1.012 1.012 0 0 1 9 9.5a.984.984 0 0 1-.31-.05h-.006L0 6.553V14.5a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V6.554L9.316 9.45H9.31z"/><path d="M16 3.5h-4v-1a2.006 2.006 0 0 0-2-2H8a2.006 2.006 0 0 0-2 2v1H2A2 2 0 0 0 .26 4.54L9 7.45l8.74-2.91A2 2 0 0 0 16 3.5zm-6 0H8v-1h2v1z"/></g></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"group w-[14px] mr-1 fill-neutral md:group-hover:fill-primary md:transition-all md:duration-300 md:ease-in-out " + (currentMenu === 2?"md:fill-primary":"")} viewBox="0 0 18 18"><path d="M6 18H1a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm11 0h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zM6 7H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm11.708-1.76-3.535 3.535a1 1 0 0 1-1.414 0L9.224 5.24a1 1 0 0 1 0-1.413L12.76.292a1 1 0 0 1 1.414 0l3.535 3.535a1 1 0 0 1-.002 1.414z"/></svg>
                                        <span>Experience</span>
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={(e) => {e.preventDefault(); setMobMenu(false); navigate("/contact");}} className={"block group py-2 pl-3 pr-4 rounded text-neutral hover:bg-base_20 md:p-0 md:hover:text-primary md:hover:bg-transparent md:transition-all md:duration-300 md:ease-in-out " + (currentMenu === 3?"bg-base_20 md:bg-base md:text-primary":"")}>
                                    <span className="flex md:bg-left-bottom md:bg-gradient-to-r md:from-primary md:to-primary md:bg-[length:0%_3px] md:bg-no-repeat md:group-hover:bg-[length:100%_3px] md:transition-all md:duration-500 md:ease-out md:py-[3px]">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="group w-[20px] mr-1 stroke-neutral md:group-hover:stroke-alternative md:transition-all md:duration-300 md:ease-in-out" fill="none" viewBox="0 0 24 24"><rect width="16" height="12" x="4" y="6" stroke-width="2" rx="2"/><path stroke-width="2" d="m4 9 7.106 3.553a2 2 0 0 0 1.788 0L20 9"/></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"group w-[14px] mr-1 fill-neutral md:group-hover:fill-primary md:transition-all md:duration-300 md:ease-in-out " + (currentMenu === 3?"md:fill-primary":"")} viewBox="0 0 18 18"><path d="M17.46.5a.662.662 0 0 0-.2.03C17.26.54.35 6.73.35 6.73a.578.578 0 0 0-.35.53.54.54 0 0 0 .26.47c.02.01 3.39 2.08 4.46 2.733a.6.6 0 0 1 .28.57c-.09.8-.28 2.59-.28 2.612a.56.56 0 0 0 .55.56l2.16-.01a.52.52 0 0 1 .45.276l1.64 2.776a.57.57 0 0 0 .47.255.53.53 0 0 0 .48-.3l7.46-15.9a.442.442 0 0 0 .07-.25.557.557 0 0 0-.54-.55zM6.97 11.85l.38-3.54L17.62.95z"/></svg>
                                        <span>Contact</span>
                                    </span>
                                </Link>                      
                            </li>
                        </ul>
                    {/* </ClickOutsideListener> */}
                </div>
                
            </div>
        </nav>
        </>
    );
} 